import baseJson from "../config/base.json";
import axios from "axios";
import Cookies from "js-cookie";

var _settings: any = null;
const getSettings = () => {
  if (_settings !== null) {
    return _settings;
  }
  _settings = new Settings();
  console.log(_settings, "_settings");
  return _settings;
};

type DOMAINS = keyof typeof baseJson;
type HOST = DOMAINS | `${DOMAINS}:${number}`;

class Settings {
  USE_CONFIG?: string; // select key in base.json before override 
  API_HOST: string;
  FAST_API: string;
  WS_HOST: string;
  STATIC_HOST: string;
  SEQUENCE: any;
  TAGOPTIONS_URL: string;
  WEB_RTC: string;
  WEB_RTC_BUCKET: string;
  APP_NAME: string;
  USERNAME_PASSWORD_LOGIN: boolean;
  EMAIL_LOGIN: boolean;
  PENTA_ACC_FACEBOOK_LOGIN: boolean;
  PENTA_ACC_LINE_LOGIN: boolean;
  PENTA_ACC_EMAIL_LOGIN: boolean;
  RECAPTCHA_KEY: boolean;
  APPOINTMENT_CHAT_FUNCTION: boolean;
  BOOKING_FUNCTION: boolean;
  DASHBOARD: boolean;
  FORM_ANALYTICS: boolean;
  WEB_PUSH_PUBLIC_KEY: string;
  LATEST_ENCOUNTER_TOP: string;
  HIDE_QUEUE_FILTER_DATE: string;
  USE_PAYMENT_STATUS_LABEL: string;
  OPEN_FORM_ENCOUNTER: string;
  IGNORE_CAN_CHAT: string;
  HIDE_CALL_BUTTON: boolean;
  HIDE_CALL_BUTTON_STAFF_CHAT: boolean;
  SHOW_CLASSIFY_BUTTON_CHAT: boolean;
  SHOW_ASSESSMENT_BUTTON_CHAT: boolean;
  SHOW_CLASSIFY_BUTTON_STAFF_CHAT: boolean;
  SHOW_ASSESSMENT_BUTTON_STAFF_CHAT: boolean;
  QUEUE_API_V2: boolean;
  HIDE_QUEUE: string;
  HIDE_SIDEBAR: string;
  HIDE_DIVISION: string;
  HIDE_NOTE: string;
  HIDE_CLASSIFY: string;
  COMPANY: string;
  PROJECT: string;
  SETTINGS: boolean;
  STUDIO: boolean;
  VACCINE_UPLOAD: boolean;
  MANAGE_SCHEDULE: boolean;
  CLASSIFY_CHAT: boolean;
  HIDE_CLASSIFY_SUBMENU_CHAT: boolean;
  HIDE_CLASSIFY_SUBMENU_ASSESSMENT: boolean;
  HIDE_CLASSIFY_SUBMENU_PROGRESS_NOTE: boolean;
  HIDE_CLASSIFY_SUBMENU_NURSE_NOTE: boolean;
  HIDE_CLASSIFY_SUBMENU_VITAL_SIGN: boolean;
  HIDE_CLASSIFY_SUBMENU_VACCINE_NOTE: boolean;
  HIDE_CLASSIFY_SUBMENU_PROFILE: boolean;
  HIDE_CLASSIFY_BUTTON_NEW_PATIENT: boolean;
  CLASSIFY_CHAT_FORM_HOST: string;
  PDF_PRINT_DIAGFORM_ID: number[];
  SHOW_BIND_HN: boolean;
  GCS_BUCKET_NAME: string;
  AIRFLOW_CHAT: boolean;
  USER_LIST_CHAT: boolean;
  UNSIGNED_FILTER: boolean;
  ENCOUNTER_CHAT: boolean;
  COPY_LINK: boolean;
  CREATE_OFFICIAL_ACCOUNT: boolean;
  HIDE_ASSESSMENT: boolean;
  PATIENT_PANEL_INVOICE: boolean;
  CALL_RECEIVED_RECLASSIFY: boolean;
  CONFIG_UPDATE_SUCCESS: boolean;
  DOMAIN: string;
  USE_LOCAL_PRINT_BASE64: boolean;
  FAVOR: any;
  SCREEN_ITEM: any;
  DISABLED_POSTPONE: boolean;
  DISABLED_WAITINGLIST: boolean;
  FIREBASE_PROJECT: string;
  SHOW_CLASSIFY_FILTER_PATIENT_QUEUE: boolean;
  QUEUE_DOCTOR_REFRESH_MINUTE: number;
  AIRFLOW_CHAT_BY_DJANGO: boolean;
  V3_HIDDEN_TAB_LIST: string[];
  PATIENT_IMAGE_URL: string;
  PATIENT_IMAGE_URL_TOKEN: string;
  BYPASS_SERVICE_WORKER: boolean;
  LOGIN_PAGE_WHEN_NO_NETWORK: boolean;
  ENABLE_TELEPHAR: boolean;
  ENABLE_ECLINICAL: boolean;
  FAX_CLAIM_AUTHORIZE: boolean;
  POLLING_TRANSPORT_TELEPHAR_SEC: number;
  SHOW_DENTAL_HISTORY: string;
  SHOW_PATIENT_INFO_FROM_BDMS: boolean;
  HIDDEN_MENU_SERVICE_QUEUE: boolean;
  TELEPHAR_PROVIDER: number;

  // Sapiens Issue #64830
  BIL_RECEIPT: string;

  // Sapiens Issue #68037
  HIDE_BIL_MEDICAL_FEE_PAY: boolean;

  // Sapiens Issue #67903
  HIDE_APPOINTMENT_BARCODE: boolean;

  // Sapiens Issue #69055
  BIL_AUTO_RUN_RECEIPT: boolean;

  // Feature #65177 Header Print List
  ENABLE_HEADER_PRINTLIST: boolean;
  // #65797 Disable Med Cert Header
  DISABLE_HEADER_MEDCERT: boolean;

  // Issue 65507 Hide Logo print (รายการผลแลป)
  HIDE_COMPANY_LOGO_PRINT: boolean;
  // Raksthai Mod
  RAKSTHAI_HIDE_PHV_SUBMENU: boolean;
  RAKSTHAI_SUPPLY_ORDER_HOMEOPD: boolean;
  RAKSTHAI_LAB_REPORT_BUTTON: boolean;
  RAKSTHAI_HIDE_SUPPLY_SUBMENU: boolean;
  RAKSTHAI_ENCOUNTER_STATUS: boolean;
  RAKSTHAI_TAB_DEFAULT_MENU: boolean;
  RAKSTHAI_HIDE_LAB_BLOODBOOK: boolean;
  RAKSTHAI_STOCK_REPORT: boolean;
  RAKSTHAI_DISABLED_USER_MODE: boolean;
  SPECIMEN_MANAGEMENT_LAB_REPORT: boolean;
  // #64570 - disabled แผนกที่สั่ง
  SPECIMEN_MANAGEMENT_DISABLED_DIV_FILTER: boolean;
  // ramasri
  // #65340 - feature intra telephar
  ENABLE_INTRA_TELEPHAR: boolean;

  // CUDENT
  // #64373 - ซ่อนนิสิต/นักศึกษาแพทย์
  CUDENT_ENCOUNTER_STUDENT: boolean;
  CUDENT_CANCEL_CHECKOUT_SUPERVISOR: boolean;
  HIDE_DOSE_CALCULATOR: boolean;

  LOGO_REPORT: { type: number; width: number; height: number; src: string };
  SIDEMENU_SCREEN_CONFIG: boolean;
  SCAN_VIA_FILE_BROWSE: boolean;
  // COMPANY_LOGO_NAME: string; // ไปใช้ COMPANY แทน CU,SAPIENS,BDSM,CNMI,RAKSTHAI,TU,MSK
  // #67574 - hide staff chat admin tab
  HIDE_STAFF_CHAT_ADMIN_MODE: boolean;
  // #67071
  HIDE_DOCTOR_FEE_ALL_MENU: boolean;
  DISABLE_CACHE: boolean = false;

  DISABLE_FEATURE_OFFLINE: boolean;
  // #65211
  SHOW_DOCTOR_CHAIR: boolean;
  // #67075
  HIDE_TELEMED: boolean;
  // #66764
  ENABLE_PRICE_LEVEL: boolean;
  ENABLE_DUCKDB: boolean;
  // #67890
  FEATURE_DISABLED_UP_TO_VERS: Record<string, boolean>;

  // #68021
  HIDE_BILLING_REPORT: string[];

  // #68213
  OR_APPOINTMENT_FORM: string;

  // #68028
  DISABLE_ONE_DOSE_ORDER_MED_EXTERNAL: boolean;

  // #67524
  PACS_BASE_URL: string;

  // #69876 - ซ่อนรายการหัตถการ
  HIDE_OPERATING_TREATMENT_OR: boolean;

  // #69882
  DOCTOR_FEE_USER_FILTER: boolean;

  // #69537
  HIDE_PROCEDURE_CHECKLIST_OR: boolean;

  // #68895
  // AGENT_URL: string;
  AGENT_LIST: string[];

  // #71019
  ENABLE_ENCOUNTER_LOCKER: boolean;

  // #71022
  RIS_BASE_URL: string;

  // #71512 ทำชั่วคราว
  DISABLE_COPY_WATERMARK_RECEIPT: boolean;

  // #69770
  ENCOUNTER_PEDING_PAYMENT_CHECK: boolean;
  // Production 
  // hostname -> match base.json -> use config 
  // hostname:port (host) -> match base.json -> use config
  // 

  constructor(selectBaseJson?: any) {
    let currentHost: string = globalThis.location.host;

    // กันไม่ให้พลาดตรง config.ts
    if (process.env.REACT_APP_PLAYWRIGHT_CONFIG_URL) {
      currentHost = process.env.REACT_APP_PLAYWRIGHT_CONFIG_URL as string;
      this.DISABLE_CACHE = false;
    } else if (process.env.NODE_ENV === "production") {
      currentHost = globalThis.location.host;
      this.DISABLE_CACHE = false;
    } else if (process.env.NODE_ENV === "development") {
       // devDomain key (e.g. 192.168.56.44, sapiens-staging.ishealth.app)
       // devDomain key (only 192.168.56.44:11000 + devmode -> config set to http://${host}) //
      let devDomain: HOST = "192.168.56.44";
      this.DISABLE_CACHE = true;
      currentHost = devDomain;
    }

    let loadBaseJson = baseJson;
    if (selectBaseJson) {
      loadBaseJson = selectBaseJson;
    }

    this.DOMAIN = "default"; // ใช้สำหรับ update Config

    // if (checkPortList?.map((i: any) => i.split(":")[0])?.some((i:any) => i === domain) ) {
    //   let found = checkPortList?.find((i: any) => i.split(":")[0] === domain)
    // }

    let keyWithPort = this.matchPattern(currentHost, Object.keys(loadBaseJson)?.filter((i: any) => i.includes(":")));
    console.log('Found specific port keyWithPort: ', keyWithPort);
    let favor: any = {}

    // url of api server
    if (keyWithPort) {
      console.log(" find with Host with port ")
      favor = this.selectDomainWithPort(currentHost, loadBaseJson);
    } else {
      console.log(" find with hostname only ")
      favor = this.selectDomain(currentHost?.split(":")?.[0], loadBaseJson);
    }
    
    console.log("base.json favor: ", favor);
    console.log('base.json currentHost: ', currentHost);
    this.FAVOR = favor;

    // console.log("base.json base", baseJson);
    // console.log("base.json baseJson", selectBaseJson);
    // console.log("base.json loadBaseJson: ", loadBaseJson);

    this.API_HOST = favor.API_HOST || ( (process.env.NODE_ENV === "development" && currentHost.includes("192.168")) ? `http://${currentHost}`: globalThis.location.origin );

    // url of fastapi server
    this.FAST_API = favor.FAST_API || globalThis.location.origin;

    // websocket server host name
    this.WS_HOST = favor.WS_HOST || globalThis.location.hostname;
    // static host for api such as TRIAGE_LEVEL_CLASSIFY
    this.STATIC_HOST = favor.STATIC_HOST || "https://admin.ishealth.app";

    this.SEQUENCE = favor.SEQUENCE;

    // tagOptions
    this.TAGOPTIONS_URL = favor.TAGOPTIONS_URL || "";

    // host of web rtc server
    this.WEB_RTC = favor.WEB_RTC || "https://webrtc.drjaysayhi.com";
    this.WEB_RTC_BUCKET = favor.WEB_RTC_BUCKET || "my-b-plus-staging-webrtc";

    this.APP_NAME = favor.APP_NAME || "ishealth";

    this.USERNAME_PASSWORD_LOGIN =
      favor.USERNAME_PASSWORD_LOGIN === undefined ? true : favor.USERNAME_PASSWORD_LOGIN;

    this.EMAIL_LOGIN = favor.EMAIL_LOGIN === undefined ? false : favor.EMAIL_LOGIN;

    // Enable accounts.thevc authentication options

    this.PENTA_ACC_FACEBOOK_LOGIN =
      favor.PENTA_ACC_FACEBOOK_LOGIN === undefined ? true : favor.PENTA_ACC_FACEBOOK_LOGIN;
    this.PENTA_ACC_LINE_LOGIN =
      favor.PENTA_ACC_LINE_LOGIN === undefined ? true : favor.PENTA_ACC_LINE_LOGIN;
    this.PENTA_ACC_EMAIL_LOGIN =
      favor.PENTA_ACC_EMAIL_LOGIN === undefined ? true : favor.PENTA_ACC_EMAIL_LOGIN;

    this.RECAPTCHA_KEY =
      favor.RECAPTCHA_KEY === undefined
        ? "6LdvmfUUAAAAAIb2DBfgd_xeN-xk4b6qq_3aR-UH"
        : favor.RECAPTCHA_KEY;

    // Tab

    // Booking function
    this.BOOKING_FUNCTION = false;

    //Queue TV
    this.QUEUE_DOCTOR_REFRESH_MINUTE =
      favor.QUEUE_DOCTOR_REFRESH_MINUTE === undefined ? false : favor.QUEUE_DOCTOR_REFRESH_MINUTE;

    // Dashboard
    this.DASHBOARD = false;

    this.FORM_ANALYTICS = false;

    this.SETTINGS = false;

    this.STUDIO = false;

    this.VACCINE_UPLOAD = false;

    this.MANAGE_SCHEDULE = false;

    this.CLASSIFY_CHAT = false;

    this.AIRFLOW_CHAT = false;

    this.USER_LIST_CHAT = false;

    this.ENCOUNTER_CHAT = true;

    this.APPOINTMENT_CHAT_FUNCTION = false;

    this.SHOW_BIND_HN = false;

    this.COPY_LINK = false;

    this.CREATE_OFFICIAL_ACCOUNT = false;

    this.CONFIG_UPDATE_SUCCESS = false;

    // ส่วนที่มีการใช้ config
    // รายการรอรับชำระและคืนเงิน - Filter
    this.FEATURE_DISABLED_UP_TO_VERS = {};

    this.CALL_RECEIVED_RECLASSIFY =
      favor.CALL_RECEIVED_RECLASSIFY === undefined ? false : favor.CALL_RECEIVED_RECLASSIFY;

    this.PATIENT_PANEL_INVOICE =
      favor.PATIENT_PANEL_INVOICE === undefined ? false : favor.PATIENT_PANEL_INVOICE;

    this.HIDE_ASSESSMENT = favor.HIDE_ASSESSMENT === undefined ? false : favor.HIDE_ASSESSMENT;

    this.UNSIGNED_FILTER = favor.UNSIGNED_FILTER === undefined ? false : favor.UNSIGNED_FILTER;

    this.HIDE_CLASSIFY_SUBMENU_CHAT =
      favor.HIDE_CLASSIFY_SUBMENU_CHAT === undefined ? false : favor.HIDE_CLASSIFY_SUBMENU_CHAT;

    this.HIDE_CLASSIFY_SUBMENU_ASSESSMENT =
      favor.HIDE_CLASSIFY_SUBMENU_ASSESSMENT === undefined
        ? false
        : favor.HIDE_CLASSIFY_SUBMENU_ASSESSMENT;

    this.HIDE_CLASSIFY_SUBMENU_PROGRESS_NOTE =
      favor.HIDE_CLASSIFY_SUBMENU_PROGRESS_NOTE === undefined
        ? false
        : favor.HIDE_CLASSIFY_SUBMENU_PROGRESS_NOTE;

    this.HIDE_CLASSIFY_SUBMENU_NURSE_NOTE =
      favor.HIDE_CLASSIFY_SUBMENU_NURSE_NOTE === undefined
        ? false
        : favor.HIDE_CLASSIFY_SUBMENU_NURSE_NOTE;

    this.HIDE_CLASSIFY_SUBMENU_VITAL_SIGN =
      favor.HIDE_CLASSIFY_SUBMENU_VITAL_SIGN === undefined
        ? false
        : favor.HIDE_CLASSIFY_SUBMENU_VITAL_SIGN;

    this.HIDE_CLASSIFY_SUBMENU_VACCINE_NOTE =
      favor.HIDE_CLASSIFY_SUBMENU_VACCINE_NOTE === undefined
        ? false
        : favor.HIDE_CLASSIFY_SUBMENU_VACCINE_NOTE;

    this.HIDE_CLASSIFY_SUBMENU_PROFILE =
      favor.HIDE_CLASSIFY_SUBMENU_PROFILE === undefined
        ? false
        : favor.HIDE_CLASSIFY_SUBMENU_PROFILE;

    this.HIDE_CLASSIFY_BUTTON_NEW_PATIENT =
      favor.HIDE_CLASSIFY_BUTTON_NEW_PATIENT === undefined
        ? false
        : favor.HIDE_CLASSIFY_BUTTON_NEW_PATIENT;

    this.CLASSIFY_CHAT_FORM_HOST = favor.CLASSIFY_CHAT_FORM_HOST || "https://ishealth.app";

    this.GCS_BUCKET_NAME = favor.GCS_BUCKET_NAME || "mybplus-waiting-list-staging";

    this.PDF_PRINT_DIAGFORM_ID =
      favor.PDF_PRINT_DIAGFORM_ID === undefined ? [] : favor.PDF_PRINT_DIAGFORM_ID;

    // Webpush
    this.WEB_PUSH_PUBLIC_KEY =
      favor.WEB_PUSH_PUBLIC_KEY ||
      "BDKyMQyvGoPQ5LvRPBmQGec1_geS441vKvkdYhaNM4WDfd0a403b0BTunKNBST_-mcaR5at3B8FYL-N4xo4x9a0";

    this.LATEST_ENCOUNTER_TOP =
      favor.LATEST_ENCOUNTER_TOP === undefined ? false : favor.LATEST_ENCOUNTER_TOP;

    this.HIDE_QUEUE_FILTER_DATE =
      favor.HIDE_QUEUE_FILTER_DATE === undefined ? false : favor.HIDE_QUEUE_FILTER_DATE;

    this.USE_PAYMENT_STATUS_LABEL =
      favor.USE_PAYMENT_STATUS_LABEL === undefined ? false : favor.USE_PAYMENT_STATUS_LABEL;

    this.OPEN_FORM_ENCOUNTER =
      favor.OPEN_FORM_ENCOUNTER === undefined ? true : favor.OPEN_FORM_ENCOUNTER;

    this.IGNORE_CAN_CHAT = favor.IGNORE_CAN_CHAT === undefined ? false : favor.IGNORE_CAN_CHAT;

    this.HIDE_CALL_BUTTON = favor.HIDE_CALL_BUTTON === undefined ? false : favor.HIDE_CALL_BUTTON;

    this.HIDE_CALL_BUTTON_STAFF_CHAT =
      favor.HIDE_CALL_BUTTON_STAFF_CHAT === undefined ? false : favor.HIDE_CALL_BUTTON_STAFF_CHAT;

    this.SHOW_CLASSIFY_BUTTON_CHAT =
      favor.SHOW_CLASSIFY_BUTTON_CHAT === undefined ? false : favor.SHOW_CLASSIFY_BUTTON_CHAT;

    this.SHOW_ASSESSMENT_BUTTON_CHAT =
      favor.SHOW_ASSESSMENT_BUTTON_CHAT === undefined ? false : favor.SHOW_ASSESSMENT_BUTTON_CHAT;

    this.SHOW_CLASSIFY_BUTTON_STAFF_CHAT =
      favor.SHOW_CLASSIFY_BUTTON_STAFF_CHAT === undefined
        ? false
        : favor.SHOW_CLASSIFY_BUTTON_STAFF_CHAT;

    this.SHOW_ASSESSMENT_BUTTON_STAFF_CHAT =
      favor.SHOW_ASSESSMENT_BUTTON_STAFF_CHAT === undefined
        ? false
        : favor.SHOW_ASSESSMENT_BUTTON_STAFF_CHAT;

    this.AIRFLOW_CHAT_BY_DJANGO =
      favor.AIRFLOW_CHAT_BY_DJANGO === undefined ? false : favor.AIRFLOW_CHAT_BY_DJANGO;

    // // #57150
    // ([
    //   // Menu : Search Patient
    //   "emergency", // - เปิดประวัติหมู่
    //   // Menu : Service_Queue
    //   "receive", // - Receive & Return ยา/เวชภัณฑ์
    //   "med_admin", // - Med Admin
    //   "verbal_order", // - Verbal order
    //   "reject_order", // - คำสั่งรอแก้ไช
    //   "phv", // - Patient History Viewer
    //   "specimen_management", // - Specimen Management
    //   "report_order", // - รายงาน
    //   // Menu : Appointment
    //   "appointment-list", // - นัดหมายวันนี้
    //   "patient-appointment", // - ทำนัดหมาย
    //   "appointment-summary", // - สรุปนัดหมาย
    //   "listmybplus", // - นัดหมาย Telemed
    //   "dash-appointment", // - Dashboard นัดหมาย
    //   // Menu : Med Order
    //   "ONE_DOSE_OPD", // - Med Order One Dose OPD
    //   // Menu : Admin
    //   "ตั้งค่าแพกเกจ",
    //   "สร้างแผนกเพิ่ม",
    //   "ตั้งค่าเอกสารยินยอม",
    //   "ตั้งค่าชุดทางเลือกการรักษา",
    //   "ตั้งค่าแผนก",
    //   "จัดการสิทธิ",
    //   "ตั้งค่าแจ้งเตือนเรียกคิว",
    //   "ตั้งค่าแจ้งเตือนนัดหมาย",
    //   "ลงตารางแพทย์",
    //   "บริหารเก้าอี้",
    //   "ลงตารางเภสัช",
    //   "สร้างตารางคลินิก",
    //   "ตั้งค่าวันหยุด",
    //   "ข้อมูลสถิติ",
    //   "ข้อมูลบุคลากร",
    //   "Studio",
    //   "VaccineUpload",
    //   "Upload ข้อมูลผู้ใช้สิทธิ",
    //   "ตั้งค่ากฏการเบิก",
    //   "รับชำระเงินต้นสังกัด",
    //   "รายงานทางการเงิน",
    //   "รายงานประจำคลินิก",
    //   "ออกใบแจ้งหนี้ / ตั้งงวด",
    //   "เตรียมข้อมูล E-Claim",
    //   "AIPN Send Claim",
    //   "บันทึกไฟล์ตอบกลับ",
    //   "import ค่า Lab สำหรับคำนวณรายได้แพทย์",
    //   "พิมพ์เอกสารภาษี",
    //   "สรุปค่าตอบแทนแพทย์",
    //   "ศูนย์ต้นทุนรายใบเสร็จ",
    //   "รายงานบัญชี",
    //   "OPD Claim Resolve",
    //   "OPD AR Transaction",
    //   "OPD รายการที่สร้างเลขงวดแล้ว",
    //   "OPD Send Claim",
    //   "IPD AR Transaction",
    //   "IPD Send Claim Info",
    //   "IPD Send Claim Result",
    //   "จัดการตารางห้องผ่าตัด",
    //   "Anesthesia Template",
    //   "Segment tool",
    //   "Debug"
    //   "Doctor Schedule",
    //   "Staff Chat",
    //   "Doctor Schedule Old",
    //   "DoctorFee_Report_Old",
    //   "DoctorFee_Report",
    //   "Telemed record",
    //   "Profile",
    //   // Menu : รคส.แพทย์
    //   "VerbalOrderImaging",
    //   // Menu : สั่งออเดอร์ล่วงหน้า
    //   "ORPreOrderImaging"
    // ])
    this.V3_HIDDEN_TAB_LIST =
      favor.V3_HIDDEN_TAB_LIST === undefined ? [] : favor.V3_HIDDEN_TAB_LIST;

    this.PATIENT_IMAGE_URL = favor.PATIENT_IMAGE_URL === undefined ? "" : favor.PATIENT_IMAGE_URL;

    this.PATIENT_IMAGE_URL_TOKEN =
      favor.PATIENT_IMAGE_URL_TOKEN === undefined ? "" : favor.PATIENT_IMAGE_URL_TOKEN;

    // api V2 for penguin
    this.QUEUE_API_V2 = favor.QUEUE_API_V2 === undefined ? false : favor.QUEUE_API_V2;

    // ---------------------------------- for CNMI telemed ----------------------------------
    this.HIDE_QUEUE = favor.HIDE_QUEUE === undefined ? false : favor.HIDE_QUEUE;

    this.HIDE_SIDEBAR = favor.HIDE_SIDEBAR === undefined ? false : favor.HIDE_SIDEBAR;

    this.HIDE_DIVISION = favor.HIDE_DIVISION === undefined ? false : favor.HIDE_DIVISION;

    this.HIDE_NOTE = favor.HIDE_NOTE === undefined ? false : favor.HIDE_NOTE;

    this.HIDE_CLASSIFY = favor.HIDE_CLASSIFY === undefined ? false : favor.HIDE_CLASSIFY;

    // --------------------------------------------------------------------------------------

    this.USE_LOCAL_PRINT_BASE64 =
      favor.USE_LOCAL_PRINT_BASE64 === undefined ? false : favor.USE_LOCAL_PRINT_BASE64;

    this.COMPANY = favor.COMPANY === undefined ? "" : favor.COMPANY;

    this.PROJECT = favor.PROJECT === undefined ? "" : favor.PROJECT;

    this.SCREEN_ITEM = [];

    this.DISABLED_POSTPONE =
      favor.DISABLED_POSTPONE === undefined ? false : favor.DISABLED_POSTPONE;

    this.DISABLED_WAITINGLIST =
      favor.DISABLED_WAITINGLIST === undefined ? false : favor.DISABLED_WAITINGLIST;

    this.FIREBASE_PROJECT = favor.FIREBASE_PROJECT === undefined ? "" : favor.FIREBASE_PROJECT;

    this.SHOW_CLASSIFY_FILTER_PATIENT_QUEUE =
      favor.SHOW_CLASSIFY_FILTER_PATIENT_QUEUE === undefined
        ? false
        : favor.SHOW_CLASSIFY_FILTER_PATIENT_QUEUE;

    this.BYPASS_SERVICE_WORKER =
      favor.BYPASS_SERVICE_WORKER === undefined ? false : favor.BYPASS_SERVICE_WORKER;

    this.LOGIN_PAGE_WHEN_NO_NETWORK =
      favor.LOGIN_PAGE_WHEN_NO_NETWORK === undefined ? false : favor.LOGIN_PAGE_WHEN_NO_NETWORK;

    this.ENABLE_TELEPHAR = favor.ENABLE_TELEPHAR === undefined ? false : favor.ENABLE_TELEPHAR;

    this.ENABLE_ECLINICAL = favor.ENABLE_ECLINICAL === undefined ? false : favor.ENABLE_ECLINICAL;

    this.FAX_CLAIM_AUTHORIZE =
      favor.FAX_CLAIM_AUTHORIZE === undefined ? false : favor.FAX_CLAIM_AUTHORIZE;

    this.POLLING_TRANSPORT_TELEPHAR_SEC =
      favor.POLLING_TRANSPORT_TELEPHAR_SEC === undefined ? 0 : favor.POLLING_TRANSPORT_TELEPHAR_SEC;

    this.SHOW_DENTAL_HISTORY =
      favor.SHOW_DENTAL_HISTORY === undefined ? "" : favor.SHOW_DENTAL_HISTORY;

    this.SHOW_PATIENT_INFO_FROM_BDMS =
      favor.SHOW_PATIENT_INFO_FROM_BDMS === undefined ? false : favor.SHOW_PATIENT_INFO_FROM_BDMS;

    this.HIDDEN_MENU_SERVICE_QUEUE =
      favor.HIDDEN_MENU_SERVICE_QUEUE === undefined ? false : favor.HIDDEN_MENU_SERVICE_QUEUE;

    this.RAKSTHAI_HIDE_PHV_SUBMENU =
      favor.RAKSTHAI_HIDE_PHV_SUBMENU === undefined ? false : favor.RAKSTHAI_HIDE_PHV_SUBMENU;

    this.RAKSTHAI_SUPPLY_ORDER_HOMEOPD =
      favor.RAKSTHAI_SUPPLY_ORDER_HOMEOPD === undefined
        ? false
        : favor.RAKSTHAI_SUPPLY_ORDER_HOMEOPD;

    this.RAKSTHAI_HIDE_SUPPLY_SUBMENU =
      favor.RAKSTHAI_HIDE_SUPPLY_SUBMENU === undefined ? false : favor.RAKSTHAI_HIDE_SUPPLY_SUBMENU;

    this.RAKSTHAI_ENCOUNTER_STATUS =
      favor.RAKSTHAI_ENCOUNTER_STATUS === undefined ? false : favor.RAKSTHAI_ENCOUNTER_STATUS;

    this.RAKSTHAI_TAB_DEFAULT_MENU =
      favor.RAKSTHAI_TAB_DEFAULT_MENU === undefined ? false : favor.RAKSTHAI_TAB_DEFAULT_MENU;

    this.TELEPHAR_PROVIDER = favor.TELEPHAR_PROVIDER === undefined ? 0 : favor.TELEPHAR_PROVIDER;

    this.LOGO_REPORT =
      favor.LOGO_REPORT === undefined
        ? [
            { type: 1, width: 600, height: 202, src: "/static/images/logochula_dent.jpg" },
            { type: 3, width: 600, height: 600, src: "/static/images/logochula.png" },
          ]
        : favor.LOGO_REPORT;

    this.SHOW_DOCTOR_CHAIR =
      favor.SHOW_DOCTOR_CHAIR === undefined ? false : favor.SHOW_DOCTOR_CHAIR;

    this.HIDE_TELEMED = favor.HIDE_TELEMED === undefined ? false : favor.HIDE_TELEMED;

    this.ENABLE_PRICE_LEVEL =
      favor.ENABLE_PRICE_LEVEL === undefined ? false : favor.ENABLE_PRICE_LEVEL;

    this.SIDEMENU_SCREEN_CONFIG =
      favor.SIDEMENU_SCREEN_CONFIG === undefined ? false : favor.SIDEMENU_SCREEN_CONFIG;

    this.RAKSTHAI_HIDE_LAB_BLOODBOOK =
      favor.RAKSTHAI_HIDE_LAB_BLOODBOOK === undefined ? false : favor.RAKSTHAI_HIDE_LAB_BLOODBOOK;

    this.RAKSTHAI_LAB_REPORT_BUTTON =
      favor.RAKSTHAI_LAB_REPORT_BUTTON === undefined ? false : favor.RAKSTHAI_LAB_REPORT_BUTTON;

    this.RAKSTHAI_STOCK_REPORT =
      favor.RAKSTHAI_STOCK_REPORT === undefined ? false : favor.RAKSTHAI_STOCK_REPORT;

    this.RAKSTHAI_DISABLED_USER_MODE =
      favor.RAKSTHAI_DISABLED_USER_MODE === undefined ? false : favor.RAKSTHAI_DISABLED_USER_MODE;

    this.SPECIMEN_MANAGEMENT_LAB_REPORT =
      favor.SPECIMEN_MANAGEMENT_LAB_REPORT === undefined
        ? false
        : favor.SPECIMEN_MANAGEMENT_LAB_REPORT;

    this.SCAN_VIA_FILE_BROWSE =
      favor.SCAN_VIA_FILE_BROWSE === undefined ? false : favor.SCAN_VIA_FILE_BROWSE;

    this.CUDENT_CANCEL_CHECKOUT_SUPERVISOR =
      favor.CUDENT_CANCEL_CHECKOUT_SUPERVISOR === undefined
        ? false
        : favor.CUDENT_CANCEL_CHECKOUT_SUPERVISOR;

    this.HIDE_DOSE_CALCULATOR =
      favor.HIDE_DOSE_CALCULATOR === undefined ? false : favor.HIDE_DOSE_CALCULATOR;

    this.CUDENT_ENCOUNTER_STUDENT =
      favor.CUDENT_ENCOUNTER_STUDENT === undefined ? false : favor.CUDENT_ENCOUNTER_STUDENT;

    this.ENABLE_INTRA_TELEPHAR =
      favor.ENABLE_INTRA_TELEPHAR === undefined ? false : favor.ENABLE_INTRA_TELEPHAR;

    this.BIL_RECEIPT = favor.BIL_RECEIPT === undefined ? "" : favor.BIL_RECEIPT;

    this.HIDE_BIL_MEDICAL_FEE_PAY =
      favor.HIDE_BIL_MEDICAL_FEE_PAY === undefined ? false : favor.HIDE_BIL_MEDICAL_FEE_PAY;

    this.HIDE_APPOINTMENT_BARCODE =
      favor.HIDE_APPOINTMENT_BARCODE === undefined ? false : favor.HIDE_APPOINTMENT_BARCODE;

    this.BIL_AUTO_RUN_RECEIPT =
      favor.BIL_AUTO_RUN_RECEIPT === undefined ? false : favor.BIL_AUTO_RUN_RECEIPT;

    this.ENABLE_HEADER_PRINTLIST =
      favor.ENABLE_HEADER_PRINTLIST === undefined ? false : favor.ENABLE_HEADER_PRINTLIST;

    this.HIDE_COMPANY_LOGO_PRINT =
      favor.HIDE_COMPANY_LOGO_PRINT === undefined ? false : favor.HIDE_COMPANY_LOGO_PRINT;

    this.SPECIMEN_MANAGEMENT_DISABLED_DIV_FILTER =
      favor.SPECIMEN_MANAGEMENT_DISABLED_DIV_FILTER === undefined
        ? false
        : favor.SPECIMEN_MANAGEMENT_DISABLED_DIV_FILTER;

    this.DISABLE_HEADER_MEDCERT =
      favor.DISABLE_HEADER_MEDCERT === undefined ? false : favor.DISABLE_HEADER_MEDCERT;

    // this.COMPANY_LOGO_NAME = favor.COMPANY_LOGO_NAME === undefined ? "" : favor.COMPANY_LOGO_NAME // ไปใช้ COMPANY

    this.HIDE_DOCTOR_FEE_ALL_MENU =
      favor.HIDE_DOCTOR_FEE_ALL_MENU === undefined ? false : favor.HIDE_DOCTOR_FEE_ALL_MENU;

    this.DISABLE_FEATURE_OFFLINE =
      favor.DISABLE_FEATURE_OFFLINE === undefined ? false : favor.DISABLE_FEATURE_OFFLINE;

    this.HIDE_STAFF_CHAT_ADMIN_MODE =
      favor.HIDE_STAFF_CHAT_ADMIN_MODE === undefined ? false : favor.HIDE_STAFF_CHAT_ADMIN_MODE;

    this.ENABLE_DUCKDB = favor.ENABLE_DUCKDB === undefined ? false : favor.ENABLE_DUCKDB;

    this.HIDE_BILLING_REPORT =
      favor.HIDE_BILLING_REPORT === undefined ? [] : favor.HIDE_BILLING_REPORT;

    this.OR_APPOINTMENT_FORM =
      favor.OR_APPOINTMENT_FORM === undefined ? "" : favor.OR_APPOINTMENT_FORM;

    this.DISABLE_ONE_DOSE_ORDER_MED_EXTERNAL =
      favor.DISABLE_ONE_DOSE_ORDER_MED_EXTERNAL === undefined
        ? false
        : favor.DISABLE_ONE_DOSE_ORDER_MED_EXTERNAL;

    this.PACS_BASE_URL = favor.PACS_BASE_URL === undefined ? "" : favor.PACS_BASE_URL;

    this.RIS_BASE_URL = favor.RIS_BASE_URL === undefined ? "" : favor.RIS_BASE_URL;

    this.HIDE_OPERATING_TREATMENT_OR =
      favor.HIDE_OPERATING_TREATMENT_OR === undefined ? false : favor.HIDE_OPERATING_TREATMENT_OR;

    this.DOCTOR_FEE_USER_FILTER =
      favor.DOCTOR_FEE_USER_FILTER === undefined ? false : favor.DOCTOR_FEE_USER_FILTER;

    this.HIDE_PROCEDURE_CHECKLIST_OR =
      favor.HIDE_PROCEDURE_CHECKLIST_OR === undefined ? false : favor.HIDE_PROCEDURE_CHECKLIST_OR;

    // this.AGENT_URL = favor.AGENT_URL === undefined ? "https://scan_local.rama.mahidol.ac.th:5555" : favor.AGENT_URL

    this.AGENT_LIST =
      favor.AGENT_LIST === undefined
        ? ["https://scan_local.rama.mahidol.ac.th:5555"]
        : favor.AGENT_LIST;

    this.ENABLE_ENCOUNTER_LOCKER =
      favor.ENABLE_ENCOUNTER_LOCKER === undefined ? false : favor.ENABLE_ENCOUNTER_LOCKER;

    this.DISABLE_COPY_WATERMARK_RECEIPT =
      favor.DISABLE_COPY_WATERMARK_RECEIPT === undefined
        ? false
        : favor.DISABLE_COPY_WATERMARK_RECEIPT;

    this.ENCOUNTER_PEDING_PAYMENT_CHECK = favor.ENCOUNTER_PEDING_PAYMENT_CHECK === undefined ? false : favor.ENCOUNTER_PEDING_PAYMENT_CHECK

    this.updateConfig();

    this.updateVersion();
  }

  selectDomainWithPort(host: string, baseJson: any) {
    console.log('selectDomainWithPort host:', host, "domain: ", "baseJson: ", baseJson);
    let keyMatch = this.matchPattern(host, Object.keys(baseJson));

    if (keyMatch) {
      this.DOMAIN = keyMatch;

      let useConfig: any= {};
      let config = (baseJson as { [key: string]: any })[keyMatch];

      if (config?.USE_CONFIG) {
        useConfig = this.selectDomain(host?.split(":")?.[0], baseJson);
      }

      useConfig = { ...useConfig, ...config };
      delete useConfig.USE_CONFIG;

      console.log('selectDomainWithPort useConfig: ', useConfig);
      return useConfig;
    } else if (!("default" in baseJson)) {
      throw `cannot find "${keyMatch}" or "default" in settings`;
    }
    return baseJson.default;
  }

  selectDomain(domain: string, baseJson: any) {
    const findDomain = Object.keys(baseJson).find((key: string) =>
      new RegExp(`^${key}$`).test(domain)
    );

    if (findDomain) {
      this.DOMAIN = findDomain;

      console.log(" selectDomain foundDomain findDomain: ", findDomain )
      return (baseJson as { [key: string]: any })[findDomain];
    } else if (!("default" in baseJson)) {
      throw `cannot find "${domain}" or "default" in settings`;
    }
    return baseJson.default;
  }

  updateConfig() {
    const apiToken = Cookies.get("apiToken");
    if (typeof apiToken === "undefined" || apiToken === "") return;
    axios
      .get(`${this.API_HOST}/users/apis/screen/`, {
        headers: { Authorization: `Token ${Cookies.get("apiToken")}` },
        params: { limit: 9999 },
      })
      .then((res) => {
        const findItem = res.data?.items?.find(
          (item: any) => JSON.parse(item.extra || "{}")[this.DOMAIN]
        );
        const extra: any = JSON.parse(findItem?.extra || "{}")[this.DOMAIN] || {};
        for (const key in this) {
          if (this.FAVOR[key] !== undefined || extra[key] !== undefined) {
            this[key] = extra[key] ?? this.FAVOR[key];
          }
        }
        this.CONFIG_UPDATE_SUCCESS = true;
        this.SCREEN_ITEM = res.data?.items;
      })
      .catch((error) => {
        for (const key in this) {
          if (this.FAVOR[key] !== undefined) {
            this[key] = this.FAVOR[key];
          }
        }
        this.CONFIG_UPDATE_SUCCESS = true;
      });
  }

  matchPattern(host: string, keys: string[]) {
    // console.log('matchPattern host: ', host, 'keys: ', keys);
    return keys.find((key: string) => {
      // Replace * with a regex wildcard
      const regexPattern = key.replace(/\*/g, ".*");
      const regex = new RegExp(`^${regexPattern}$`);
      return regex.test(host);
    });
  }

  updateVersion() {
    const apiToken = Cookies.get("apiToken");

    if (apiToken === undefined || apiToken === "") {
      return;
    }

    axios
      .get(`${this.API_HOST}/version/`, {
        headers: { Authorization: `Token ${Cookies.get("apiToken")}` },
      })
      .then((res) => {
        const vertions = [202401];
        const backendVersion: string = res.data.version;

        for (const vertion of vertions) {
          const vertionYYYYMM = Number.parseInt(backendVersion.slice(0, 6));

          this.FEATURE_DISABLED_UP_TO_VERS[vertion] = vertionYYYYMM <= vertion;
        }

        return res;
      })
      .catch((error) => {
        console.error("Cannot get version data.", error);
      });
  }

  recreate(baseJson: any) {
    _settings = new Settings(baseJson);
  }
}

// export default new Settings();
export default getSettings();
